import { StrictMode } from 'react'
import { getSentryEnv } from '@arteli/utils'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import App from '@/components/App'
import IntlProvider from '@/components/IntlProvider'
import '@arteli/global-styles'

if (import.meta.env.VITE_SENTRY_DSN) {
  const host = location.hostname

  if (host === 'localhost') {
    console.log('Sentry is disabled because the host is localhost')
  } else {
    Sentry.init({ dsn: import.meta.env.VITE_SENTRY_DSN, environment: getSentryEnv(host) })
  }
}

// For TDG data with MSW please use the following url params: ?categoryId=3.C.TW&storeIds=12.S.9e
// const { setupWorker } = await import('msw/browser')
// const { handlers } = await import('./mocks/handlers')
// const worker = setupWorker(...handlers)
// worker.start({ onUnhandledRequest: 'bypass' })

const root = ReactDOM.createRoot(document.getElementById('root') || document.body)

root.render(
  <StrictMode>
    <IntlProvider>
      <App />
    </IntlProvider>
  </StrictMode>
)
